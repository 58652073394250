import React from "react";
import { Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import HomepageLayout from "./containers/Home";
import ProductList from "./containers/ProductList";
import OrderSummary from "./containers/OrderSummary";
import Confira from "./containers/Confira";
import Vantagens from "./containers/Vantagens";
import Agenda from "./containers/Agenda";
import Cadastros from "./containers/Cadastros";
import Relatorios from "./containers/Relatorios";
import RelatoSinais from "./containers/RelatoSinais";
import RelatorioAdmin from "./containers/RelatorioAdmin";
import RelatorioEventosAdmin from "./containers/RelatorioEventosAdmin";
import RelatorioDocumentosAdmin from "./containers/RelatorioDocumentosAdmin";
import RelatorioSolicitacoesAdmin from "./containers/RelatorioSolicitacoesAdmin";
import RelatoEventos from "./containers/RelatoEventos";
import RelatoDocumentos from "./containers/RelatoDocumentos";
import RelatoSolicitacoes from "./containers/RelatoSolicitacoes";
import EventoForm from "./containers/EventoForm";
import DocumentoForm from "./containers/DocumentoForm";
import SinalForm from "./containers/SinalForm";
import SolicitacaoForm from "./containers/SolicitacaoForm";
import PacienteForm from "./containers/PacienteForm";
import ProfissionalForm from "./containers/ProfissionalForm";
import ContatoForm from "./containers/ContatoForm";
import ParceiroForm from "./containers/ParceiroForm";
import Tempo from "./tempo/Tempo";
import Covid from "./tempo/Covid";

const BaseRouter = () => (
  <Hoc>
    <Route path="/covid" component={Covid} /> 
    <Route path="/tempo" component={Tempo} /> 
    <Route path="/confira" component={Confira} />    
    <Route path="/cadastros" component={Cadastros} />
    <Route path="/relatorios" component={Relatorios} />
    <Route path="/relatosinais" component={RelatoSinais} />
    <Route path="/relatorioadmin" component={RelatorioAdmin} />
    <Route path="/relatoeventos" component={RelatoEventos} />
    <Route path="/relatorioeventosadmin" component={RelatorioEventosAdmin} />
    <Route path="/relatodocumentos" component={RelatoDocumentos} />
    <Route path="/relatoriodocumentosadmin" component={RelatorioDocumentosAdmin} />
    <Route path="/relatosolicitacoes" component={RelatoSolicitacoes} />
    <Route path="/relatoriosolicitacoesadmin" component={RelatorioSolicitacoesAdmin} />
    <Route path="/cad-evento" component={EventoForm} />
    <Route path="/cad-documento" component={DocumentoForm} />
    <Route path="/cad-sinais" component={SinalForm} />
    <Route path="/cad-solicitacao" component={SolicitacaoForm} />
    <Route path="/cad-paciente" component={PacienteForm} />
    <Route path="/cad-profissional" component={ProfissionalForm} />
    <Route path="/cad-parceiro" component={ParceiroForm} />
    <Route path="/contato" component={ContatoForm} />
    <Route path="/vantagens" component={Vantagens} />
    <Route path="/agenda" component={Agenda} />
    <Route path="/products" component={ProductList} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/order-summary" component={OrderSummary} />
    <Route exact path="/" component={HomepageLayout} />
  </Hoc>
);

export default BaseRouter;
