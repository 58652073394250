import React from "react";
import {
    Container,
    Header,
} from "semantic-ui-react";

class Vantagens extends React.Component {

    componentDidMount() {

    }

    render() {

        return (
            <Container>
                <br /><br />
                <Header>Vantagens de nossos serviços</Header>

                <p style={{ fontSize: "1.13em" }}>

                Menor risco de infecção para o paciente, que não está em ambiente hospitalar; <br />
                Maior qualidade de vida durante o tratamento;
                <br />
                Realizamos o atendimento em domicílio, disponibilizando a prestação de serviços para os seguintes procedimentos: 
                <br /> 
                <ul>
                • Aplicação de medicamentos (EV, IM, SC e oral)<br />
                • Realização de curativos convencionais e industrializados<br />
                • Tratamento de lesões de pele (escaras)<br />
                • Retirada de pontos<br />
                • Clistes/lavagem intestinal<br />
                • Preparo de pacientes para exames (colonoscopia)<br />
                • Tricotomia<br />
                • Realização de banhos de aspersão, banho de leito, maior higiene e conforto para o paciente<br />
                • Aspirações<br />
                • Cuidados com drenos e cateteres<br />
                • Troca de bolsa de colostomia<br />
                • Orientação do profissional quanto à pacientes acamados, senil e portadores de sequelas neurológicas (cuidados gerais e específicos)<br />
                • Acompanhamento à exames médicos, consultas, vacinação, fisioterapia, serviços bancários, compras, salão de beleza, SPA, academia, lazer, etc<br />
                • Agenda virtual para acompanhamento das atividas e rotinas por toda familia<br />
                • Relatórios<br />
                • Digitalização de exames e procedimentos<br />
                • Veículo próprio para deslocamento.<br />
                </ul>
                </p>

            </Container>
        );
    }
}

export default Vantagens;
