import React from "react";
import {
    Container,
    Dimmer,
    Header,
    Image,
    Label,
    Loader,
    Table,
    Button,
    Message,
    Segment,
    Modal,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { solicitacoesURL, pacienteURL } from "../constants";
import Moment from 'react-moment';
import logo from './img/logo-anjos4-01.png';

class RelatoSolicitacoes extends React.Component {
    state = {
        data: null,
        error: null,
        loading: false,
        photo: null,
    };

    componentDidMount() {
        this.handleFetchSolicitacoes();
        this.handleFetchPhoto();
    }

    handleFetchSolicitacoes = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${solicitacoesURL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false,
                    total: res.data.length,
                    nome: res.data[0].nome
                });
                //console.log("RelatoSolicitacoes.js handleFetchSolicitacoes GET data:");
                //console.log(this.state.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchPhoto = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    photo: res.data[0].photo,
                    loading: false,
                    nome: res.data[0].nome
                });
                //console.log("RelatoPhoto.js handleFetchPhoto GET data:");
                //console.log(res.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { data, error, loading, total, nome, photo } = this.state;
        //console.log("RelatoSinais.js render() data:");
        //console.log(data);
        return (
            <Container>
                <br /> <br />
                <Header>Relatório de Solicitações: <br /><br />
                    <Label as='a' image>
                        {(photo !== null) ? <img src={photo} alt="" /> : <img src={logo} alt="" />}
                        {nome}
                    </Label>
                </Header>
                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {data && (
                    <Table celled>

                        <Table.Header>
                            <Table.Row>

                                <Table.HeaderCell>Data</Table.HeaderCell>
                                <Table.HeaderCell>Área</Table.HeaderCell>
                                <Table.HeaderCell>Especialidade</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {data.map((solicitacao, i) => {
                                return (
                                    <Table.Row >
                                        <Table.Cell>    
                            
                    <Modal trigger={
                        <Label color="green" ribbon>
                            <Link>STATUS</Link>
                        </Label>
                    }>

                        <Modal.Header>{solicitacao.nome}</Modal.Header>
                        <Modal.Content image>

                            <Image wrapped size='medium' src={photo} />

                            <Modal.Description>
                                <Header><h1>Solicitação</h1></Header>
                                <p><h3>Status: {solicitacao.status}</h3></p>
                                <p><h4>Data: <Moment format="DD/MM/YYYY <hh:mm a>">{solicitacao.created}</Moment></h4></p>
                                <p><h3>Área: {solicitacao.area}</h3></p>
                                <p><h3>Especialidade: {solicitacao.especialidade}</h3></p>
                                <p><h3>Local: {solicitacao.local}</h3></p>
                                <p><h3>Telefone: {solicitacao.fone1}</h3></p>
                                <p><h3>Whatsapp: {solicitacao.whatsapp}</h3></p>
                                <p><h3>Descrição:
                                    <br />{solicitacao.descricao}</h3></p>
                            </Modal.Description>
                        </Modal.Content>

                    </Modal>                                                                                  
            
                    <Moment format="DD/MM/YYYY <hh:mm a>">
                        {solicitacao.created}
                    </Moment>
                                        

                                        </Table.Cell>
                                            
                                        <Table.Cell>{solicitacao.area}</Table.Cell>

                                        <Table.Cell >{solicitacao.especialidade} </Table.Cell>
                                    </Table.Row>
                                );
                            })}

                            <Table.Row>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell textAlign="center" colSpan="2">
                                    Total de solicitações: {total}
                                </Table.Cell>
                            </Table.Row>

                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="5">
                                    <Button floated="right" color="yellow">
                                        <Link to="agenda">Painel do Cliente</Link>
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>
                )}
            </Container>
        );
    }
}

export default RelatoSolicitacoes;
