import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Modal,
  Popup,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Statistic
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from './img/logo-anjos4-01.png';
import cliente1 from './img/cliente-01.png';
//import Palmas from '../Palmas/Palmas'

const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        />
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        {children}
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node
};

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "4em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>

          <Grid.Column floated="left" width={6}>
            <Image
              bordered
              rounded
              size="medium"
              src={logo}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Melhor qualidade em atendimento
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Enfermagem domiciliar personalizado. Cuidado e atenção especial do seu anjo da saúde.
              <br />
              Contate-nos e saiba mais sobre nossos serviços.
            </p>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Buscando um profissional humanizado ?
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              Se você pensa em buscar um profissional para cuidar de um idoso ou ente querido de sua
              família, confira nossas dicas a seguir ...
            </p>
          </Grid.Column>
          
        </Grid.Row>

        <Grid.Row>

          <Grid.Column textAlign="center">
            
            {/*<Link to="/confira"><Button size="huge">Confira</Button></Link> */}

            <Modal trigger={
              <Label color="teal" ribbon>
                <Link>Confira</Link>
              </Label>
            }>
              <Modal.Header>Confira !!!</Modal.Header>
              <Modal.Content image>

                <Image wrapped size='medium' src={logo} />

                <Modal.Description>
                  <Header><h1>Carinho e Atenção</h1></Header>
                  <p style={{ fontSize: "1.13em" }}>


                    Se você pensa em buscar um profissional para cuidar de um idoso ou ente querido de sua família, confira nossas dicas a seguir.
               <br />
                    Quem tem um familiar idoso ou ente querido que precisa de atenção e cuidados especiais diariamente sabe que a rotina não é nada fácil.
               <br />
                    Abaixo são exemplos de como podemos montar de forma rápida e eficiente um acompanhamento presencial e virtual de seu ente querido:
                <br /><br />
                    1. Melhorar a atenção ao idoso e/ou ente querido<br />
                    2. Elevar a qualidade de vida da família<br />
                    3. Ganhar apoio em emergências<br />
                    4. Garantir o uso correto da medicação<br />
                    5. Contar com um profissional qualificado<br />
                    6. Oferecer o contato com outra pessoa<br />
                    7. Cuidador Pessoal Virtual<br />
                    8. Filtre profissional por bairro, cidade, estado, especialidade, ranking<br />

                  </p>

                </Modal.Description>
              </Modal.Content>

            </Modal>  
    	    <br /><br />
            <Label color="orange" ribbon>
              <Link to="/tempo">Check o tempo</Link>
            </Label>
	    <br /><br />
            <Label color="red" ribbon>
              <Link to="/covid">Dados Covid</Link>
            </Label>
          
          </Grid.Column>

        </Grid.Row>

      </Grid>
    </Segment>
    <Segment style={{ padding: "0em" }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
	    <Header as="h3" style={{ fontSize: "2em" }} textAlign="center">
              "Atenção, carinho e atendimentos são perfeitos !"
            </Header>

	     <p style={{ fontSize: "1.33em" }}>
              <b>Valéria</b>, muito profissionalismo.
            </p>

            
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "Finalmente encontramos um serviço de qualidade que atende com carinho e atenção."
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              <Image avatar src={cliente1} />
              <b>Emerson</b>, filho atencioso que se preocupa com o bem estar dos pais e familia.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment style={{ padding: "4em 0em" }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Acesse o aplicativo do Anjos da Saúde e tenha seu histórico médico em suas mãos.
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          • Agenda Virtual, acompanhamento das atividas e rotinas por toda familia <br />
          • Relatórios<br />
          • Digitalização de exames e procedimentos
        </p>

        <Popup content='Faça o login para acessar o app Anjos da Saúde'
          trigger={
            <Label color="teal" ribbon>
              <Link to="/login">
                Acesse o aplicativo
            </Link>
            </Label>
          } />

        

        <br /><br />
        <Statistic.Group>
          <Statistic>
            <Statistic.Value>
              <Icon name='heartbeat' />
              22
              </Statistic.Value>
            <Statistic.Label><Link>Sinais Vitais</Link></Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value tex>
              <Icon name='medkit' />
              7
            </Statistic.Value>
            <Statistic.Label><Link>Eventos</Link></Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value>
              <Icon name='print' />
              15
            </Statistic.Value>
            <Statistic.Label><Link>Digitalizados</Link></Statistic.Label>
          </Statistic>
          <Statistic>
            <Statistic.Value>
              <Icon name='chart line' />
              5
            </Statistic.Value>
            <Statistic.Label><Link>Relatórios</Link></Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value>
              <Image src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' inline circular />
              24
            </Statistic.Value>
            <Statistic.Label><Link>Interações</Link></Statistic.Label>
          </Statistic>

        </Statistic.Group>
        
        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase", fontSize: "2em" }}
        >
          
          Serviços
          
        </Divider>

        <Header as="h3" style={{ fontSize: "2em" }}>
          Muito mais vantagens e benefícios 
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          • Acompanhamento terapêutico <br />
          • Participação social e cotidiano<br />
          • Professora de tendências e tecnologia<br />
          • Anjo Virtual<br />
          • Plano mensal e avulso
        </p>      
        
        <Modal trigger={
          <Label color="teal" ribbon>
            <Link>Vantagens</Link>
          </Label>
        }>
          <Modal.Header>Vantagens de nossos serviços</Modal.Header>
          <Modal.Content image>

            <Image wrapped size='medium' src={logo} />

            <Modal.Description>
              <Header><h1>Veja os serviços prestados</h1></Header>
              <p style={{ fontSize: "1.13em" }}>

                Menor risco de infecção para o paciente, que não está em ambiente hospitalar; <br />
                Maior qualidade de vida durante o tratamento;
                <br />
                Realizamos o atendimento em domicílio, disponibilizando a prestação de serviços para os seguintes procedimentos:
                <br />
                <ul>
                  • VM (Ventilador Mecânico)<br />
                  • Aplicação de medicamentos (EV, IM, SC e oral)<br />
                  • Realização de curativos convencionais e industrializados<br />
                  • Tratamento de lesões de pele (escaras)<br />
                  • Retirada de pontos<br />
                  • Clistes/lavagem intestinal<br />
                  • Preparo de pacientes para exames (colonoscopia)<br />
                  • Tricotomia<br />
                  • Realização de banhos de aspersão, banho de leito, maior higiene e conforto para o paciente<br />
                  • Aspirações<br />
                  • Cuidados com drenos e cateteres<br />
                  • Troca de bolsa de colostomia<br />
                  • Orientação do profissional quanto à pacientes acamados, senil e portadores de sequelas neurológicas (cuidados gerais e específicos)<br />
                  • Acompanhamento à exames médicos, consultas, vacinação, fisioterapia, serviços bancários, compras, salão de beleza, SPA, academia, lazer, etc<br />
                  • Agenda virtual para acompanhamento das atividas e rotinas por toda familia<br />
                  • Relatórios<br />
                  • Digitalização de exames e procedimentos<br />
                  • Veículo próprio para deslocamento.<br />
                </ul>
              </p>

            </Modal.Description>
          </Modal.Content>

        </Modal>  

        
        

      </Container>
    </Segment>
  </ResponsiveContainer>
);
export default HomepageLayout;
