import React from "react";
import {
    Container,
    Header,
    Statistic,
    Icon,
    Image
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { pacienteURL } from "../constants";
import { authAxios } from "../utils";

class Relatorios extends React.Component {

    state = {
        error: null,
        loading: false,
        paciente: null,
        cpf: null,
        grupo: null,
        photo: null

    };

    componentDidMount() {
        this.handleFetchPaciente();
    }

    handleFetchPaciente = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    paciente: res.data[0].nome,
                    photo: res.data[0].photo,
                    cpf: res.data[0].paciente_cpf,
                    grupo: res.data[0].grupo,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { photo } = this.state;
        return (
            <Container>
                <br /><br />
                <Header>Relatórios de Cliente</Header>
                
                <Statistic.Group>
                    <Statistic>
                        <Statistic.Value>
                            <Icon name='heartbeat' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="relatorioadmin">Sinais Vitais</Link></Statistic.Label>
                    </Statistic>

                    <Statistic>
                        <Statistic.Value tex>
                            <Icon name='medkit' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="relatorioeventosadmin">Eventos</Link></Statistic.Label>
                    </Statistic>

                    <Statistic>
                        <Statistic.Value>
                            <Icon name='print' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="relatoriodocumentosadmin">Digitalização</Link></Statistic.Label>
                    </Statistic>

 
                    <Statistic>
                        <Statistic.Value>
                            <Icon name='address book outline' />
                        </Statistic.Value>
                        <Statistic.Label><Link to="relatoriosolicitacoesadmin">Interações</Link></Statistic.Label>
                    </Statistic>

                </Statistic.Group>


            </Container>
        );
    }
}

export default Relatorios;
