import React from "react";
import {
    Container,
    Dimmer,
    Header,
    Image,
    Label,
    Loader,
    Table,
    Button,
    Message,
    Segment,
    Modal,
    Form,
    Input,
    Popup
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { documentosAdminURL } from "../constants";
import Moment from 'react-moment';
import logo from './img/logo-anjos4-01.png';

class RelatorioDocumentosAdmin extends React.Component {
    state = {
        data: null,
        error: null,
        loading: false,
        pacienteFiltro: null,
        formData: {
            paciente: ''
        },
        visible: true,
    };

    componentDidMount() {
        this.handleFetchDocumentos();
    }

    handleFetchDocumentos = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${documentosAdminURL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false,
                    total: res.data.length,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false, visible: true });
            });
    };

    handleFiltro = (paciente) => {
        this.setState({
            pacienteFiltro: paciente,
        });
    };

    handleChange = e => {
        const { formData } = this.state;
        const updatedFormdata = {
            ...formData,
            [e.target.name]: e.target.value
        };
        this.setState({
            formData: updatedFormdata
        });
    };

    handleDismiss = () => {
        this.setState({ visible: false, error: null })
    }

    render() {
        const { data, error, loading, total, pacienteFiltro, formData } = this.state;

        return (
            <Container>
                <br /> <br />
                <Header>Relatório de Documentos: <br /><br />
                    <Form onSubmit={() => this.handleFiltro(formData.paciente)}>
                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Input}
                                name="paciente"
                                label='Paciente'
                                placeholder='CPF do paciente (apenas números)'
                                onChange={this.handleChange}
                                value={formData.paciente}
                            />

                        </Form.Group>

                        <Form.Field control={Button} primary>Buscar</Form.Field>

                    </Form>
                </Header>
                {error && (
                    <Message
                        error
                        visible
                        onDismiss={this.handleDismiss}
                        header="Ocorreu um erro"
                        content={JSON.stringify(error.message)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {data && (
                    (pacienteFiltro && pacienteFiltro !== '')
                        ?
                        <Table celled>
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell>Data</Table.HeaderCell>
                                    <Table.HeaderCell>CPF</Table.HeaderCell>
                                    <Table.HeaderCell>Nome</Table.HeaderCell>
                                    <Table.HeaderCell>Assunto</Table.HeaderCell>
                                    <Table.HeaderCell>Detalhes</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {data.map((documento, i) => {
                                    return (
                                        (documento.paciente_cpf.includes(pacienteFiltro))
                                            ?
                                            <Table.Row key={i}>
                                                <Table.Cell><Moment format="DD/MM/YYYY">{documento.created}</Moment></Table.Cell>
                                                <Table.Cell>{documento.paciente_cpf}</Table.Cell>
                                                <Table.Cell>{documento.nome}</Table.Cell>
                                                <Table.Cell>
                                                    <Label color="brown" ribbon>
                                                        <Popup content='Faça o download deste arquivo'
                                                            trigger={<a href={documento.arquivo} download> {documento.assunto} </a>} />

                                                    </Label>
                                                </Table.Cell>

                                                <Table.Cell>

                                                    <Modal trigger={
                                                        <Label color="green" ribbon>
                                                            <Link>TIPO</Link>
                                                        </Label>
                                                    }>
                                                        <Modal.Header>{documento.nome}</Modal.Header>
                                                        <Modal.Content image>

                                                            <Image wrapped size='medium' src={logo} />

                                                            <Modal.Description>
                                                                <Header><h1>Registro de documento</h1></Header>
                                                                <p><h3>Data: <Moment format="DD/MM/YYYY">{documento.created}</Moment></h3></p>
                                                                <p><h3>Evento: {documento.tipo}</h3></p>
                                                                <p><h3>Assunto: {documento.assunto}</h3></p>
                                                                <p><h3>Descrição:
                                                            <br />{documento.descricao}</h3></p>

                                                            </Modal.Description>
                                                        </Modal.Content>

                                                    </Modal>

                                                    {documento.tipo}

                                                </Table.Cell>
                                            </Table.Row>

                                            : ""

                                    );
                                })}
                                

                            </Table.Body>

                        </Table>


                        :


                        <Table celled>
                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell>Data</Table.HeaderCell>
                                    <Table.HeaderCell>CPF</Table.HeaderCell>
                                    <Table.HeaderCell>Nome</Table.HeaderCell>
                                    <Table.HeaderCell>Assunto</Table.HeaderCell>
                                    <Table.HeaderCell>Evento</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {data.map((documento, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell><Moment format="DD/MM/YYYY">{documento.created}</Moment></Table.Cell>
                                            <Table.Cell>{documento.paciente_cpf}</Table.Cell>
                                            <Table.Cell>{documento.nome}</Table.Cell>
                                            <Table.Cell>
                                                <Label color="brown" ribbon>
                                                    <Popup content='Faça o download deste arquivo'
                                                        trigger={<a href={documento.arquivo} download> {documento.assunto} </a>} />

                                                </Label>
                                            </Table.Cell>

                                            <Table.Cell>

                                                <Modal trigger={
                                                    <Label color="green" ribbon>
                                                        <Link>TIPO</Link>
                                                    </Label>
                                                }>
                                                    <Modal.Header>{documento.nome}</Modal.Header>
                                                    <Modal.Content image>

                                                        <Image wrapped size='medium' src={logo} />

                                                        <Modal.Description>
                                                            <Header><h1>Registro de documento</h1></Header>
                                                            <p><h3>Data: <Moment format="DD/MM/YYYY">{documento.created}</Moment></h3></p>
                                                            <p><h3>Evento: {documento.tipo}</h3></p>
                                                            <p><h3>Assunto: {documento.assunto}</h3></p>
                                                            <p><h3>Descrição:
                                                            <br />{documento.descricao}</h3></p>

                                                        </Modal.Description>
                                                    </Modal.Content>

                                                </Modal>

                                                {documento.tipo}

                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}

                                <Table.Row>
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell textAlign="center" colSpan="2">
                                        Total de documentos: {total}
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="5">
                                        <Button floated="right" color="yellow">
                                            <Link to="relatorios">Relatórios</Link>
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>

                        </Table>

                )}

            </Container>
        );
    }
}

export default RelatorioDocumentosAdmin;
