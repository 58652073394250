import React from "react";
import {
    Container,
    Header,
    Statistic,
    Icon,
    Image,
    Message,
    Segment,
    Dimmer,
    Label,
    Loader,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { sinaisURL, pacienteURL, eventosURL, documentosURL, solicitacoesURL} from "../constants";
import logo from './img/logo-anjos4-01.png';
import Mapa from '../Mapa.js'


class Agenda extends React.Component {
    state = {
        error: null,
        loading: false,
        paciente: null,
        cpf: null,
        grupo: null,
        photo: null,
        nome: null

    };

    componentDidMount() {        
        this.handleFetchSinais();
        this.handleFetchEventos();
        this.handleFetchDocs();
        this.handleFetchSolicitacoes();
        this.handleFetchPaciente();
    }

    handleFetchSinais = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${sinaisURL}`)
            .then(res => {
                this.setState({ 
                    loading: false, 
                    total: res.data.length,
                    nome: res.data[0].nome
                });
                //console.log("Agenda.js handleFetchSinais GET data:");
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchDocs = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${documentosURL}`)
            .then(res => {
                this.setState({
                    loading: false,
                    totalDocs: res.data.length,
                    nome: res.data[0].nome
                });
                //console.log("Agenda.js handleFetchDocs GET data:");
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchEventos = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${eventosURL}`)
            .then(res => {
                this.setState({
                    loading: false,
                    totalEv: res.data.length,                    
                });
                //console.log("Agenda.js handleFetchEventos GET data:");
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchSolicitacoes = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${solicitacoesURL}`)
            .then(res => {
                this.setState({
                    loading: false,
                    totalsol: res.data.length,
                    nome: res.data[0].nome
                });
                //console.log("Agenda.js handleFetchSolicitacoes GET data:");
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchPaciente = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    paciente: res.data[0].nome,
                    nome: res.data[0].nome,
                    photo: res.data[0].photo,
                    cpf: res.data[0].paciente_cpf,
                    grupo: res.data[0].grupo,
                    loading: false,
                });
                //console.log("Agenda.js handleFetchPaciente GET data:");
                //console.log(res.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { error, loading, total, totalEv, totalDocs, totalsol, nome, photo } = this.state;
        return (  
            <Container>  
                <br />   
                {error && (
                    <Message
                        error
                        header="Ocorram alguns erros com seus dados"
                        content={JSON.stringify(error.message)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

            
                <Header style={{ color: "green" }}>Painel do Cliente</Header>
                   
                <Label as='a' image >
                    {(photo !== null) ? <img src={photo} alt="" /> : <img src={logo} alt="" />}
                    {nome}
                </Label>

                <Statistic.Group size='mini'>
                <Statistic>
                        <Statistic.Value>
                            <Icon name='heartbeat' />
                            {total}
                        </Statistic.Value>
                         <Statistic.Label><Link to="relatosinais">Sinais Vitais</Link></Statistic.Label>
                </Statistic>

                <Statistic>
                        <Statistic.Value tex>
                            <Icon name='medkit' />
                            {totalEv}
                        </Statistic.Value>
                        <Statistic.Label><Link to="relatoeventos">Eventos</Link></Statistic.Label>
                </Statistic>

                <Statistic>
                        <Statistic.Value>
                            <Icon name='print' />
                            {totalDocs}
                        </Statistic.Value>
                        <Statistic.Label><Link to="relatodocumentos">Digitalizados</Link></Statistic.Label>
                </Statistic>
                

		<Statistic>
                    <Statistic.Value>
                        <Icon name='address book outline' />
                        {totalsol}
                    </Statistic.Value>
                    <Statistic.Label><Link to="relatosolicitacoes">Solicitações</Link></Statistic.Label>
                </Statistic>

                </Statistic.Group>

		<br />
                <Mapa />

            </Container>
        );
    }
}



export default Agenda;
