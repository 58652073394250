import React, { Component } from 'react'
import {
    Container, Button, Header, Form, Input, TextArea,
    Message, Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import { authAxios } from "../utils";
import axios from "axios";
//import { Link } from "react-router-dom";
import { pacienteURL } from "../constants";
import { localhost } from "../constants";

class SolicitacaoForm extends Component {
    state = {
        error: null,
        loading: false,
        photo: {},
        paciente: null,
        cpf: null,
        arquivo: null,

    };

    componentDidMount() {

    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('paciente_cpf', event.target.elements.paciente_cpf.value);
        form_data.append('area', event.target.elements.area.value);
        form_data.append('especialidade', event.target.elements.especialidade.value);
        form_data.append('local', event.target.elements.local.value);
        form_data.append('fone1', event.target.elements.fone1.value);
        form_data.append('whatsapp', event.target.elements.whatsapp.value);
        form_data.append('username', event.target.elements.username.value);
        form_data.append('status', "aberto");
        form_data.append('descricao', event.target.elements.descricao.value);


        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        authAxios.post(`https://${localhost}/solicitacao/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.props.history.push(`/`);
                }
                this.setState({ loading: false });
            })
    };

   
    render() {
        const { error, loading, } = this.state;
        return (
            <Container>
                <br /> <br />
                <Header>Cadastro de solicitação: </Header>

                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="area" label='Área' placeholder='Área' required />
                        <Form.Field control={Input} name="especialidade" label='Especialidade' placeholder='Especialidade' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Paciente' required />
                        <Form.Field control={Input} name="paciente_cpf" label='CPF' placeholder='CPF apenas números' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="local" label='Endereço' placeholder='Endereço' />
                        <Form.Field control={Input} name="fone1" label='Fone' placeholder='Contato' />
                        <Form.Field control={Input} name="whatsapp" label='Whatsapp' placeholder='Celular' />
                        <Form.Field control={Input} name="username" label='Técnico' placeholder='CPF apenas números' />
                    </Form.Group>


                    <Form.Field control={TextArea} name="descricao" label='Descrição' placeholder='Descrição do evento' required />

                    <Form.Field control={Button} primary>Cadastrar</Form.Field>

                </Form>
            </Container>
        )
    }
}

export default SolicitacaoForm;
