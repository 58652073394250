import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Select,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import logo from './img/logo-anjos4-01.png';
//import axios from "axios";
import { authAxios } from "../utils";
import { pacienteURL, profissionalURL, parceiroURL } from "../constants";

class CustomLayout extends React.Component {

  state = {
    error: null,
    loading: false,
    paciente: null,
    cpf: null,
    pacienteGrupo: null,
    profissionalGrupo: null,
    parceiroGrupo: null,
    grupo: null
  };

  componentDidMount() {
    this.handleFetchPaciente();
    this.handleFetchProfissional();
    this.handleFetchParceiro();
  }

  handleFetchPaciente = () => {
    this.setState({ loading: true });
    authAxios
      .get(`https://${pacienteURL}`)
      .then(res => {
        this.setState({
          paciente: res.data[0].nome,
          cpf: res.data[0].paciente_cpf,
          pacienteGrupo: res.data[0].grupo,
          loading: false,
        });        
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleFetchProfissional = () => {
    this.setState({ loading: true });
    authAxios
      .get(`https://${profissionalURL}`)
      .then(res => {
        this.setState({
          paciente: res.data[0].nome,
          photo: res.data[0].photo,
          cpf: res.data[0].cpf,
          profissionalGrupo: res.data[0].grupo,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  handleFetchParceiro = () => {
    this.setState({ loading: true });
    authAxios
      .get(`https://${parceiroURL}`)
      .then(res => {
        this.setState({
          paciente: res.data[0].nome,
          photo: res.data[0].photo,
          cpf: res.data[0].cpf,
          parceiroGrupo: res.data[0].grupo,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };


  render() {
    const { authenticated } = this.props;
    const { grupo, pacienteGrupo, profissionalGrupo, parceiroGrupo } = this.state;

    const selectOptions = [
      { key: 'af', value: 'af', text: 'Link Um' },
      { key: 'ax', value: 'ax', text: 'Link Dois' },
      { key: 'al', value: 'al', text: 'Link Três' },
      { key: 'dz', value: 'dz', text: 'Link Quatro' },
    ]

    return (
      <div>
        <Menu inverted fixed="top" color="yellow">
          <Container>

            <Link to="/">
              <Menu.Item header>Home</Menu.Item>
            </Link>

            {authenticated ? (
              <React.Fragment>
                {/* 
                <Link to="/agenda">
                  <Menu.Item header>Painel</Menu.Item>
                </Link>

                <Link to="/cadastros">
                  <Menu.Item header>Cadastros</Menu.Item>
                </Link> 
                */}
                <Menu.Menu position="right" >
                  <Dropdown   
                    text="Aplicativo"                 
                    icon="first aid"
                    //loading={loading}
                    //text={`${cart !== null ? cart.order_items.length : 0}`}
                    pointing
                    className="link item"                                      
                  >
                 
                    <Dropdown.Menu > 
                      {(pacienteGrupo === "paciente") ? (
                      <Dropdown.Item
                        icon="heartbeat"
                        text="painel"
                        onClick={() =>
                          this.props.history.push("/agenda")
                        }
                      />
                      ) : ""}

                      {(pacienteGrupo === "paciente" || parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (

                      <Dropdown.Item
                        icon="address card outline"
                        text="cadastros"                        
                        onClick={() =>
                          this.props.history.push("/cadastros")
                        }
                      />
                      ) : ""}

                      {(parceiroGrupo === "parceiro" || profissionalGrupo === "admin" || parceiroGrupo === "admin") ? (

                        <Dropdown.Item
                          icon="chart line"
                          text="relatórios"
                          onClick={() =>
                            this.props.history.push("/relatorios")
                          }
                        />
                      ) : ""}
                    

                      {(grupo === "admin") ? (

                      <Dropdown.Item
                        href="https://www.anjosdasaude.net.br/admin/"
                        icon="globe"
                        text="admin"
                        target="_blank"
                      />

                      ) : ""}

                    </Dropdown.Menu>
                  </Dropdown>
                  <Menu.Item header onClick={() => this.props.logout()}>
                    <Link to="/">
                    Logout
                    </Link>
                  </Menu.Item>
                </Menu.Menu>
              </React.Fragment>
            ) : 
            
            (
              <Menu.Menu position="right">
                <Link to="/login">
                  <Menu.Item header>Login</Menu.Item>
                </Link>
                <Link to="/signup">
                  <Menu.Item header>Signup</Menu.Item>
                </Link>
              </Menu.Menu>
            )}
            <Link to="/contato">
              <Menu.Item header>Contato</Menu.Item>
            </Link>   

          </Container>
        </Menu>

        {this.props.children}

        <Segment
          color="teal"
          inverted
          vertical
          style={{ margin: "5em 0em 0em", padding: "5em 0em", }}
        >
          <Container textAlign="center" >
            <Grid divided inverted stackable>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Hospitais" />
                <List link >
                  
                    <Select placeholder='Selecione Hospital' options={selectOptions} />
                  
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Clínicas" />
                <List link >

                  <Select placeholder='Selecione Clínica' options={selectOptions} />

                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Parceiros" />
                <List link >

                  <Select placeholder='Selecione Parceiro' options={selectOptions} />

                </List>
              </Grid.Column>
              <Grid.Column width={7}>
		<Image centered size="mini" src={ logo } />
                <p>
                  Av. Argemiro Evangelista Ferreira, 839 - Gávea Sul 
                  
                  CEP. 38425-268 - Uberlândia - MG 
                  <br />
                  Fone: 34-3219-9990
                  <br />
                  Cel.: 34-98857-6527
                  <br />
                  Links para parceiros e profissionais disponíveis na região.
                  <br />
                  * Ainda não liberado para publico.
                </p>
              </Grid.Column>
            </Grid>

            <Divider inverted section />
            <List horizontal inverted divided link size="small">
              <List.Item as="a" href="#">
                Mapa do Site
              </List.Item>
              <List.Item as="a" href="#">
                Contate-nos
              </List.Item>
              <List.Item as="a" href="#">
                Termos e Condições
              </List.Item>
              <List.Item as="a" href="#">
                Política de privacidade
              </List.Item>
            </List>
          </Container>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    //cart: state.cart.shoppingCart,
    //loading: state.cart.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    //fetchCart: () => dispatch(fetchCart())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
