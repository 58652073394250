import React, { useState } from 'react';
import {
    Container,
    } from "semantic-ui-react";
import { fetchWeather } from './fetchWeather';
import './Tempo.css';

const Tempo = () => {
    const [query, setQuery] = useState('');
    const [weather, setWeather] = useState({});

    const search = async (e) => {
        if (e.key === 'Enter') {
            const data = await fetchWeather(query);

            setWeather(data);
            setQuery('');
        }
    }

    return (
        
        <Container>                   
            <div className="main-container">
                <input type="text" className="cidade" placeholder="Digite a cidade..." value={query} onChange={(e) => setQuery(e.target.value)} onKeyPress={search} />
	        <br />
                {weather.main && (
                    <div className="city">
                        <h2 className="city-name">
                            <span>{weather.name}</span>
                            <sup>{weather.sys.country}</sup>
                        </h2>
                        <div className="city-temp">
                            {Math.round(weather.main.temp)}
                            <sup>&deg;C</sup>
                        </div>
                        <div className="info">
                            <img className="city-icon" src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`} alt={weather.weather[0].description} />
			    <h3><strong>{weather.weather[0].description}</strong></h3>
                            <p>
                                <strong style={{ color: 'red' }}> Max: {Math.round(weather.main.temp_max)}</strong>
                                <strong style={{ color: 'blue' }}> Min: {Math.round(weather.main.temp_min)}</strong>
                                <strong style={{ color: 'green' }}> Hum: {weather.main.humidity}</strong>
                            </p>
			    <p>
                                <strong style={{ color: 'orange' }} > Vel. Vento: {weather.wind.speed} mt/s</strong>
                            </p>
                            <br />
                        </div>
                    </div>
                )}
            </div>  
        </Container>        
    );
}

export default Tempo;
