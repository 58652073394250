import React, { Component } from 'react'
import {
    Container, Button, Header, Form, Input, Message, Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import { authAxios } from "../utils";
import axios from "axios";
//import { Link } from "react-router-dom";
import { pacienteURL } from "../constants";
import { localhost } from "../constants";

class PacienteForm extends Component {
    state = {
        error: null,
        loading: false,
        arquivo: null,
        operador: null,
        cpf: null,
        visible: true,
    };

    componentDidMount() {

    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });        

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('paciente_cpf', event.target.elements.paciente_cpf.value);
        form_data.append('apelido', event.target.elements.apelido.value);
        form_data.append('nascimento', event.target.elements.nascimento.value);
        form_data.append('endereco', event.target.elements.endereco.value);
        form_data.append('bairro', event.target.elements.bairro.value);
        form_data.append('cep', event.target.elements.cep.value);
        form_data.append('cidade', event.target.elements.cidade.value);
        form_data.append('estado', event.target.elements.estado.value);
        form_data.append('fone1', event.target.elements.fone1.value);
        form_data.append('whatsapp', event.target.elements.whatsapp.value);
        form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('email', event.target.elements.email.value);
        form_data.append('grupo', "paciente");
        form_data.append('altura', event.target.elements.altura.value);
        form_data.append('peso', event.target.elements.peso.value);
        form_data.append('genero', event.target.elements.genero.value);
        form_data.append('etnia', event.target.elements.etnia.value);
        form_data.append('olho', event.target.elements.olho.value);
        form_data.append('sapato', event.target.elements.sapato.value);
        form_data.append('roupa', event.target.elements.roupa.value);
        form_data.append('observacao', event.target.elements.observacao.value);
        form_data.append('alergias', event.target.elements.alergias.value);
        form_data.append('tiposanguineo', event.target.elements.tiposanguineo.value);
        form_data.append('planodesaude', event.target.elements.planodesaude.value);
        form_data.append('hipertensao', event.target.elements.hipertensao.value);
        form_data.append('diabete', event.target.elements.diabete.value);
        form_data.append('conciente', event.target.elements.conciente.value);
        form_data.append('cadeirante', event.target.elements.cadeirante.value);
        form_data.append('acamado', event.target.elements.acamado.value);
        form_data.append('debulando', event.target.elements.debulando.value);
        form_data.append('usodemedicacao', event.target.elements.usodemedicacao.value);
        form_data.append('escaras', event.target.elements.escaras.value);
        form_data.append('hemodialese', event.target.elements.hemodialese.value);
        form_data.append('parkinson', event.target.elements.parkinson.value);
        form_data.append('alzheimer', event.target.elements.alzheimer.value);
        form_data.append('demencia', event.target.elements.demencia.value);
        form_data.append('neurologico', event.target.elements.neurologico.value);
        form_data.append('acompanhamentomedico', event.target.elements.acompanhamentomedico.value);
        form_data.append('tolerancia', event.target.elements.tolerancia.value);
        form_data.append('desturbio', event.target.elements.desturbio.value);
        form_data.append('dpoc', event.target.elements.dpoc.value);
        form_data.append('cataratas', event.target.elements.cataratas.value);
        form_data.append('osteoporose', event.target.elements.osteoporose.value);
        form_data.append('osteoartrite', event.target.elements.osteoartrite.value);
        form_data.append('amd', event.target.elements.amd.value);
        form_data.append('perdadeaudicao', event.target.elements.perdadeaudicao.value);
        form_data.append('insuficienciarenal', event.target.elements.insuficienciarenal.value);
        form_data.append('incontinenciaurinaria', event.target.elements.incontinenciaurinaria.value);
        form_data.append('responsavel1', event.target.elements.responsavel1.value);
        form_data.append('responsavel1_fone', event.target.elements.responsavel1_fone.value);
        form_data.append('responsavel1_profissao', event.target.elements.responsavel1_profissao.value);
        form_data.append('responsavel1_cpf', event.target.elements.responsavel1_cpf.value);
        form_data.append('responsavel1_rg', event.target.elements.responsavel1_rg.value);
        form_data.append('responsavel1_email', event.target.elements.responsavel1_email.value);
        form_data.append('cadastrante', this.state.cpf);

        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";
        console.log(form_data)

        authAxios.post(`https://${localhost}/operador/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.props.history.push(`/`);
                }
                this.setState({ loading: false });                
            })
            .catch(err => {
                this.setState({ error: err, loading: false, visible: true });
            })
    };
    
    handleChange = (event) => {
        this.setState({
            loading: true,
            arquivo: event.target.files[0],            
        })
        this.setState({ loading: false });
        //console.log(this.state.arquivo)
    }

    handleDismiss = () => {
        this.setState({ visible: false, error: null })
    }

    render() {
        const { error, loading, } = this.state;
        return (
            <Container >
                <br /> <br />
                <Header>Cadastro de Paciente: </Header>

                {error && (
                    <Message
                        error
                        visible
                        onDismiss={this.handleDismiss}
                        header="Ocorreu um erro"
                        content={JSON.stringify(error.message)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Nome completo' required />
                        <Form.Field control={Input} name="paciente_cpf" label='CPF' placeholder='CPF' required />
                        <Form.Field control={Input} name="apelido" label='Apelido' placeholder='Apelido' />
                        <Form.Field control={Input} type="date" name="nascimento" label='Nascimento' placeholder='Nascimento' />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="endereco" label='Endereço' placeholder='Endereço'  />
                        <Form.Field control={Input} name="bairro" label='Bairro' placeholder='Bairro'  />
                        <Form.Field control={Input} name="cep" label='CEP' placeholder='CEP' />
                        <Form.Field control={Input} name="cidade" label='Cidade' placeholder='Cidade' />
                        <Form.Field control={Input} name="estado" label='Estado' placeholder='Estado' />
                        <Form.Field control={Input} name="fone1" label='Fone' placeholder='Contato' />
                        
                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="whatsapp" label='Whatsapp' placeholder='Celular' />
                        <Form.Field control={Input} name="email" label='Email' placeholder='Email' required />
                        <Form.Field control={Input} name="altura" label='Altura' placeholder='Altura' />
                        <Form.Field control={Input} name="peso" label='Peso' placeholder='Peso' />
                        <Form.Field control={Input} name="genero" label='Genero' placeholder='Genero' />

                    </Form.Group>

                    <Input
                        type="file"
                        ref={(input) => { this.filesInput = input }}
                        name="file"
                        icon='file text outline'
                        iconPosition='left'
                        label='Upload Foto *'
                        labelPosition='right'
                        placeholder='UploadFoto...'
                        onChange={this.handleChange}
                        required
                    />


                    <Form.Group widths='equal'>
                        
                        <Form.Field control={Input} name="etnia" label='Etnia' placeholder='Etnia' />
                        <Form.Field control={Input} name="olho" label='Olho' placeholder='Olho' />
                        <Form.Field control={Input} name="sapato" label='Sapato' placeholder='Sapato' />
                        <Form.Field control={Input} name="roupa" label='Roupa' placeholder='Roupa' />
                        <Form.Field control={Input} name="observacao" label='Observação' placeholder='Observação' />

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="alergias" label='Alergias' placeholder='Alergias' />
                        <Form.Field control={Input} name="tiposanguineo" label='Tipo sangue' placeholder='Tipo sanguineo' />
                        <Form.Field control={Input} name="planodesaude" label='Plano de Saúde' placeholder='Plano de saúde' />
                        <Form.Field control={Input} name="hipertensao" label='Hipertençao' placeholder='Hipertençao' />
                        <Form.Field control={Input} name="diabete" label='Diabete' placeholder='Diabete' />

                    </Form.Group>

                    <Form.Group widths='equal'>
                     
                        <Form.Field control={Input} name="conciente" label='Consciente' placeholder='Consciente' />
                        <Form.Field control={Input} name="cadeirante" label='Cadeirante' placeholder='Cadeirante' />
                        <Form.Field control={Input} name="acamado" label='Acamado' placeholder='Acamado' />
                        <Form.Field control={Input} name="debulando" label='Debulando' placeholder='Debulando' />
                        <Form.Field control={Input} name="usodemedicacao" label='Uso de medicação' placeholder='Uso de medicação' />

                    </Form.Group>

                    <Form.Group widths='equal'>

                       
                        <Form.Field control={Input} name="escaras" label='Escaras' placeholder='Escaras' />
                        <Form.Field control={Input} name="hemodialese" label='Hemodiálese' placeholder='Hemodiálese' />
                        <Form.Field control={Input} name="parkinson" label='Parkinson' placeholder='Parkinson' />
                        <Form.Field control={Input} name="alzheimer" label='Alzheimer' placeholder='Alzheimer' />
                        <Form.Field control={Input} name="demencia" label='Demencia' placeholder='Demencia' />
                       

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="neurologico" label='Nerológico' placeholder='Neurológico' />
                        <Form.Field control={Input} name="acompanhamentomedico" label='Ac. Médico' placeholder='Ac. médico' />
                        <Form.Field control={Input} name="tolerancia" label='Tolerância' placeholder='Tolerância' />
                        <Form.Field control={Input} name="desturbio" label='Desturbio' placeholder='Desturbio' />
                        <Form.Field control={Input} name="dpoc" label='Dpoc' placeholder='Dpoc' />
                        <Form.Field control={Input} name="cataratas" label='Cataratas' placeholder='Cataratas' />                        

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="osteoporose" label='Osteoporose' placeholder='Osteoporose' />
                        <Form.Field control={Input} name="osteoartrite" label='Osteoartrite' placeholder='Osteoartrite' />
                        <Form.Field control={Input} name="amd" label='AMD' placeholder='AMD' />
                        <Form.Field control={Input} name="perdadeaudicao" label='Perda audição' placeholder='Perda de audição' />
                        <Form.Field control={Input} name="insuficienciarenal" label='Insufic. Renal' placeholder='Insufic. Renal' />
                        <Form.Field control={Input} name="incontinenciaurinaria" label='Incont. urinária' placeholder='Incont. urinária' />

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="responsavel1" label='Responsável' placeholder='Responsável' />
                        <Form.Field control={Input} name="responsavel1_fone" label='Fone Resp.' placeholder='Fone Resp.' />
                        <Form.Field control={Input} name="responsavel1_profissao" label='Profissão Resp.' placeholder='Profissão Resp.' />
                        <Form.Field control={Input} name="responsavel1_cpf" label='CPF Resp.' placeholder='CPF Resp.' />
                        <Form.Field control={Input} name="responsavel1_rg" label='RG Resp.' placeholder='RG Resp.' />
                        <Form.Field control={Input} name="responsavel1_email" label='Email Resp.' placeholder='Email Resp.' />

                    </Form.Group>

                    <Form.Field control={Button} primary>Cadastrar</Form.Field>

                </Form>
            </Container>
        )
    }
}

export default PacienteForm;
