import React, { Component } from 'react'
import {
    Container, Button, Header, Form, Input, TextArea,
    Message, Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import axios from "axios";
import { localhost } from "../constants";
//import { Link } from "react-router-dom";
import Mapa from '../Mapa.js'

class ContatoForm extends Component {
    state = {
        error: null,
        loading: false,
	toggle: true
    };

    componentDidMount() {
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('email', event.target.elements.email.value);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('fone', event.target.elements.fone.value);
        form_data.append('assunto', event.target.elements.assunto.value);
        form_data.append('descricao', event.target.elements.descricao.value);


        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        axios.post(`https://${localhost}/contato/`, form_data)
            .then(res => {
                if (res.status === 201) {
                   this.props.history.push(`/`);
                }
                this.setState({ loading: false });
            })
    };
    
    render() {
        const { error, loading, toggle } = this.state;
        return (
            <Container>

		<Button primary onClick={() => this.setState({toggle: false})}>
		    <br />
                    <Header>Formulário de Contato</Header>
                </Button>

		<Button orange onClick={() => this.setState({ toggle: true })}>
                    <br />
                    <Header>Mapa</Header>
                </Button>

                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

		{!toggle &&
                <Form onSubmit={this.handleFormSubmit}>
                    
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="email" label='Email' placeholder='Email' required />
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Nome' required />
                        <Form.Field control={Input} name="fone" label='Telefone' placeholder='Telefone' required />
                        <Form.Field control={Input} name="assunto" label='Assunto' placeholder='Assunto' required />
                    </Form.Group>
                    
                    <Form.Field control={TextArea} name="descricao" label='Descrição' placeholder='Descrição do assunto' required />

                    <Form.Field control={Button} primary>Enviar</Form.Field>

                </Form>
		}

		<br />
		{toggle && <Mapa />}

            </Container>
        )
    }
}

export default ContatoForm;
