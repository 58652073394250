import React, { Component } from 'react'
import {
    Container, Checkbox, Button, Header, Form, Input,
    Message, Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import { authAxios } from "../utils";
import axios from "axios";
//import { Link } from "react-router-dom";
import { pacienteURL } from "../constants";
import { localhost } from "../constants";

class ParceiroForm extends Component {
    state = {
        error: null,
        loading: false,
        arquivo: null,
        operador: null,
        cpf: null,
        checked: false,
        visible: true,
    };

    componentDidMount() {

    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('cpf', event.target.elements.cpf.value);
        form_data.append('apelido', event.target.elements.apelido.value);
        form_data.append('nascimento', event.target.elements.nascimento.value);
        form_data.append('grupo', "parceiro");
        form_data.append('endereco', event.target.elements.endereco.value);
        form_data.append('bairro', event.target.elements.bairro.value);
        form_data.append('cep', event.target.elements.cep.value);
        form_data.append('cidade', event.target.elements.cidade.value);
        form_data.append('estado', event.target.elements.estado.value);
        form_data.append('fone1', event.target.elements.fone1.value);
        form_data.append('whatsapp', event.target.elements.whatsapp.value);
        form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('email', event.target.elements.email.value);
        form_data.append('escolaridade', event.target.elements.escolaridade.value);
        form_data.append('extra', event.target.elements.extra.value);
        form_data.append('concluido', this.state.checked);
        form_data.append('cadastrante', event.target.elements.cadastrante.value);

        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";
        console.log(form_data)

        authAxios.post(`https://${localhost}/parceiro/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.props.history.push(`/`);
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ error: err, loading: false, visible: true });
            })
    };

   
    handleChange = (event) => {
        this.setState({
            loading: true,
            arquivo: event.target.files[0],
        })
        this.setState({ loading: false });
    }

    onChange(ev) {
        this.setState({ checked: !this.state.checked });
    }

    handleDismiss = () => {
        this.setState({ visible: false, error: null })
    }

    render() {
        const { error, loading, } = this.state;
        return (
            <Container >
                <br /> <br />
                <Header>Cadastro de Parceiro: </Header>

                {error && (
                    <Message
                        error
                        visible
                        onDismiss={this.handleDismiss}
                        header="Ocorreu um erro"
                        content={JSON.stringify(error.message)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Nome completo' required />
                        <Form.Field control={Input} name="cpf" label='CPF / CNPJ' placeholder='CPF / CNPJ' required />
                        <Form.Field control={Input} name="apelido" label='Apelido' placeholder='Apelido' />
                        <Form.Field control={Input} type="date" name="nascimento" label='Nascimento' placeholder='Nascimento' />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="endereco" label='Endereço' placeholder='Endereço' />
                        <Form.Field control={Input} name="bairro" label='Bairro' placeholder='Bairro' />
                        <Form.Field control={Input} name="cep" label='CEP' placeholder='CEP' />
                        <Form.Field control={Input} name="cidade" label='Cidade' placeholder='Cidade' />
                        <Form.Field control={Input} name="estado" label='Estado' placeholder='Estado' />

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field control={Input} name="fone1" label='Fone' placeholder='Contato' />
                        <Form.Field control={Input} name="whatsapp" label='Whatsapp' placeholder='Celular' required />
                        <Form.Field control={Input} name="email" label='Email' placeholder='Email' required />
                        <Form.Field control={Input} name="escolaridade" label='Escolaridade' placeholder='Escolaridade' required />
                        <Form.Field control={Input} name="extra" label='Extra' placeholder='Extra' />

                    </Form.Group>

                    <Checkbox
                        slider
                        label={<label>Coren ativo</label>}
                        checked={this.state.checked}
                        onChange={this.onChange.bind(this)}
                    />
                    <br /><br />

                    <Form.Field control={Input} name="cadastrante" label='Cadastrante' placeholder='CPF/CNPJ apenas números' required />

                    <Input
                        required
                        type="file"
                        ref={(input) => { this.filesInput = input }}
                        name="file"
                        icon='file text outline'
                        iconPosition='left'
                        label='Upload Foto *'
                        labelPosition='right'
                        placeholder='UploadFoto...'
                        onChange={this.handleChange}
                    />

                    <br /><br />
                    <Form.Field control={Button} primary>Cadastrar</Form.Field>


                </Form>
            </Container>
        )
    }
}

export default ParceiroForm;
