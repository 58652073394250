import React from "react";
import {
    Container,
    Dimmer,
    Header,
    Image,
    Label,
    Loader,
    Table,
    Button,
    Message,
    Segment,
    Modal,
    Popup
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { documentosURL, pacienteURL } from "../constants";
import Moment from 'react-moment';
import logo from './img/logo-anjos4-01.png';

class RelatoDocumentos extends React.Component {
    state = {
        data: null,
        error: null,
        loading: false,
        photo: null,
    };

    componentDidMount() {
        this.handleFetchDocumentos();
        this.handleFetchPhoto();
    }

    handleFetchDocumentos = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${documentosURL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false,
                    total: res.data.length,
                    nome: res.data[0].nome,
                    arquivo: res.data[0].arquivo
                });
                //console.log("RelatoDocumentos.js handleFetchDocumento GET data:");
                //console.log(this.state.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchPhoto = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    photo: res.data[0].photo,
                    loading: false,
                    nome: res.data[0].nome
                });
                //console.log("RelatoPhoto.js handleFetchPhoto GET data:");
                //console.log(res.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { data, error, loading, total, photo, nome } = this.state;
        //console.log("RelatoDocumentos.js render() data:");
        //console.log(data);
        return (
            <Container>
                <br /> <br />
                <Header>Relatório de Documentos: <br /><br />
                    <Label as='a' image>
                        {(photo !== null) ? <img src={photo} alt="" /> : <img src={logo} alt="" />}
                        {nome}
                    </Label> 
                </Header>
                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {data && (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>

                                <Table.HeaderCell>Data</Table.HeaderCell>
                                <Table.HeaderCell>Assunto</Table.HeaderCell>
                                <Table.HeaderCell>Detalhes</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {data.map((documento, i) => {
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell><Moment format="DD/MM/YYYY <hh:mm a>">{documento.created}</Moment></Table.Cell>
                                        <Table.Cell>
                                            <Label color="brown" ribbon>
                                <Popup content='Faça o download deste arquivo' 
                                    trigger={<a href={documento.arquivo} download> {documento.assunto} </a>} />
                                                
                                            </Label>                                            
                                        </Table.Cell>

                                        <Table.Cell>
                                    
                                    <Modal trigger={                                                            
                                            <Label color="green" ribbon>
                                                <Link>TIPO</Link>
                                            </Label>
                                        }>
                                    <Modal.Header>{documento.nome}</Modal.Header>
                                    <Modal.Content image>

                                        <Image wrapped size='medium' src={photo} />

                                        <Modal.Description>
                                            <Header><h1>Documento digitalizado</h1></Header>
                                            <p><h3>Data: <Moment format="DD/MM/YYYY HH:MM">{documento.created}</Moment></h3></p>
                                            <p><h3>Assunto: {documento.assunto}</h3></p>
                                            <p><h3>Descrição: 
                                                <br />{documento.descricao}</h3></p>

                                        </Modal.Description>
                                    </Modal.Content>

                                    </Modal>  
                                            
                                            {documento.tipo}

                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}

                            <Table.Row>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell textAlign="center" colSpan="2">
                                    Total de documentos: {total}
                                </Table.Cell>
                            </Table.Row>

                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="5">
                                    <Button floated="right" color="yellow">
                                        <Link to="agenda">Painel do Cliente</Link>
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>
                )}
            </Container>
        );
    }
}

export default RelatoDocumentos;
