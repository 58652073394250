export const localhost = "www.anjosdasaude.net.br";
//export const localhost = "127.0.0.1:8000";

const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;

export const productListURL = `${endpoint}/product-list/`;
export const addToCartURL = `${endpoint}/add-to-cart/`;
export const orderSummaryURL = `${endpoint}/order-summary/`;
export const adminURL = `${localhost}/admin/`;
export const sinaisURL = `${localhost}/sinais/`;
export const sinaisAdminURL = `${localhost}/sinaisadmin/`;
export const eventosAdminURL = `${localhost}/eventosadmin/`;
export const documentosAdminURL = `${localhost}/documentosadmin/`;
export const solicitacoesAdminURL = `${localhost}/solicitacoesadmin/`;
export const eventosURL = `${localhost}/eventos/`;
export const documentosURL = `${localhost}/digitaliza/`;
export const solicitacoesURL = `${localhost}/solicitacao/`;
export const pacienteURL = `${localhost}/cliente/`;
export const profissionalURL = `${localhost}/profissional/`;
export const parceiroURL = `${localhost}/parceiro/`;
export const operadorURL = `${localhost}/operador/`;
