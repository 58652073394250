import React from "react";
import {
    Container,
    Dimmer,
    Header,
    Image,
    Label,
    Loader,
    Table,
    Button,
    Message,
    Segment,
    Modal,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { sinaisURL, pacienteURL } from "../constants";
import Moment from 'react-moment';
import logo from './img/logo-anjos4-01.png';

class RelatoSinais extends React.Component {
    state = {
        data: null,
        error: null,
        loading: false,
        photo: null,
    };

    componentDidMount() {
        this.handleFetchSinais();
        this.handleFetchPhoto();
    }

    handleFetchSinais = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${sinaisURL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false,
                    total: res.data.length,
                    nome: res.data[0].nome
                });
                //console.log("RelatoSinais.js handleFetchSinais GET data:");
                //console.log(this.state.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchPhoto = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    photo: res.data[0].photo,
                    loading: false,   
                    nome: res.data[0].nome                 
                });
                //console.log("RelatoPhoto.js handleFetchPhoto GET data:");
                //console.log(res.data);
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { data, error, loading, total, nome, photo } = this.state;
         
        //console.log("RelatoSinais.js render() data:");
        //console.log(data);
        return (
            <Container>
                <br /> <br />
                <Header>Relatório de Sinais Vitais: <br /><br />
                    <Label as='a' image>
                        {(photo !== null) ? <img src={photo} alt="" /> : <img src={logo} alt="" /> }
                    
                        {nome}
                    </Label>
                </Header>
                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {data && (
                    <Table celled>

                        <Table.Header>
                            <Table.Row>
                                
                                <Table.HeaderCell>Data</Table.HeaderCell>
                                <Table.HeaderCell>Assunto</Table.HeaderCell>
                                <Table.HeaderCell>Observação</Table.HeaderCell>
                                
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                        {data.map((sinal, i) => {
                            return (
                                <Table.Row >
                                    <Table.Cell><Moment format="DD/MM/YYYY">{sinal.created}</Moment></Table.Cell>
                                    <Table.Cell>{sinal.assunto}</Table.Cell>

                                    <Table.Cell>                            

                        
                        <Modal trigger={
                            <Label color="green" ribbon>
                                <Link>STATUS</Link>
                            </Label>
                        }>
                        
                            <Modal.Header>{sinal.nome}</Modal.Header>
                            <Modal.Content image>

                            <Image wrapped size='medium' src={photo} />
                                                                
                                <Modal.Description>
                                    <Header><h1>Sinal aferido</h1></Header>
                                    <p><h4>Data: <Moment format="DD/MM/YYYY">{sinal.created}</Moment></h4></p>
                                    <p>Pressão Arterial: {sinal.pa} </p>
                                    <p>Frequência Cardíaca: {sinal.fc} </p>
                                    <p>Temperatura: {sinal.temp} </p>
                                    <p>Frequência Respiratória: {sinal.fr} </p>
                                    <p>Escala de dor: {sinal.escala} </p>
                                    <p>Saturação: {sinal.saturacao} </p>
                                    <p>Oxigenoterapia Litros/Min: {sinal.oxi} </p>
                                    <p>Glicose DXT: {sinal.dxt} </p>
                                    <p>Tipo de acesso venoso: {sinal.acessov} </p>
                                    <p>Sonda Vesical de Demora SVD: {sinal.svd} </p>
                                    <p>MEWS: {sinal.mews} </p>
                                    <p>Aceitação da dieta: {sinal.dieta} </p>
                                    <p>Observações: {sinal.observacao} </p>
                                </Modal.Description>
                            </Modal.Content>
                            
                        </Modal>      

                                        {sinal.status}

                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}                
                                                         
                            <Table.Row>                               
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell textAlign="center" colSpan="2">
                                    Total de aferições: {total}
                                </Table.Cell>
                            </Table.Row>

                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="5">
                                    <Button floated="right" color="yellow">
                                        <Link to="agenda">Painel do Cliente</Link>
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>
                )} 
            </Container>
            );
        }
    }

export default RelatoSinais;
