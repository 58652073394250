import React from "react";
import {
    Container,
    Header,
    Statistic,
    Icon,
    Image
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { pacienteURL, profissionalURL, parceiroURL } from "../constants";
import { authAxios } from "../utils";
import logo from './img/logo-anjos4-01.png';

class Cadastros extends React.Component {

    state = {
        error: null,
        loading: false,
        paciente: null,
        cpf: null,
        grupo: null,
        photo: null,
        pacienteGrupo: null,
        profissionalGrupo: null,
        parceiroGrupo: null,

    };

    componentDidMount() {
        this.handleFetchPaciente();
        this.handleFetchProfissional();
        this.handleFetchParceiro();
    }

    handleFetchPaciente = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${pacienteURL}`)
            .then(res => {
                this.setState({
                    paciente: res.data[0].nome,
                    photo: res.data[0].photo,
                    cpf: res.data[0].paciente_cpf,
                    pacienteGrupo: res.data[0].grupo,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchProfissional = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${profissionalURL}`)
            .then(res => {
                this.setState({
                    paciente: res.data[0].nome,
                    photo: res.data[0].photo,
                    cpf: res.data[0].cpf,
                    profissionalGrupo: res.data[0].grupo,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    handleFetchParceiro = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${parceiroURL}`)
            .then(res => {
                this.setState({
                    paciente: res.data[0].nome,
                    photo: res.data[0].photo,
                    cpf: res.data[0].cpf,
                    parceiroGrupo: res.data[0].grupo,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ error: err, loading: false });
            });
    };

    render() {
        const { photo, grupo, pacienteGrupo, profissionalGrupo, parceiroGrupo } = this.state;
        return (
            <Container>
                <br /><br />
                <Header>Painel de Cadastros</Header>
                
                <Statistic.Group>               
                
                    {(parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (    
                    <Statistic>
                        <Statistic.Value>
                            <Icon name='heartbeat' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="/cad-sinais">Sinais Vitais</Link></Statistic.Label>
                    </Statistic>
                    
                ) : "" }
                    
                    {(parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (
                    <Statistic>
                        <Statistic.Value>
                                <Icon name='calendar alternate' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="/cad-evento">Eventos</Link></Statistic.Label>
                    </Statistic>

                ) : ""}

                    {(parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (

                        <Statistic>
                            <Statistic.Value>
                                <Icon name='print' />

                            </Statistic.Value>
                            <Statistic.Label><Link to="/cad-documento">Digitalização</Link></Statistic.Label>
                        </Statistic>

                    ) : ""}
                    
                    {(pacienteGrupo === "paciente" || parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (    
                    
                    <Statistic>
                        <Statistic.Value>
                                <Icon name='address book outline' />
                            
                        </Statistic.Value>
                            <Statistic.Label><Link to="/cad-solicitacao">Interações</Link></Statistic.Label>
                    </Statistic>

                ) : ""}
                
    
                    {(parceiroGrupo === "parceiro" || profissionalGrupo === "profissional" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (    
                    <Statistic>
                        <Statistic.Value>
                                <Icon name='wheelchair' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="/cad-paciente">Paciente</Link></Statistic.Label>
                    </Statistic>

                ) : ""}
                

                    {(parceiroGrupo === "parceiro" || profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (
                    <Statistic>
                        <Statistic.Value>
                            <Icon name='user md' />

                        </Statistic.Value>
                        <Statistic.Label><Link to="/cad-profissional">Profissional</Link></Statistic.Label>
                    </Statistic>
                ) : ""}

                    {(profissionalGrupo === "admin" || parceiroGrupo === "admin" ) ? (
                        <Statistic>
                            <Statistic.Value>
                                <Icon name='ambulance' />

                            </Statistic.Value>
                            <Statistic.Label><Link to="/cad-parceiro">Parceiro</Link></Statistic.Label>
                        </Statistic>
                    ) : ""}

                </Statistic.Group>

            </Container>
        );
    }
}

export default Cadastros;
