import React, { useState, useRef, useEffect, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./Mapa.css";
import * as anjos from "./enfermeirosAnjos.json";
import logo from './containers/img/logo-anjos4-01.png';
import compass from './containers/img/compass.png';
import {
     Dimmer, Segment, Loader, Image
} from 'semantic-ui-react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import {
    //GoogleMap,
    useLoadScript,
    //Marker,
    //InfoWindow,
} from "@react-google-maps/api";

const Marker = ({ children }) => children;
const Popup = ({ children }) => children;
const Locate = ({ children }) => children;
const Search = ({ children }) => children;
const libraries = ["places"];

export default function App() {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const mapRef = useRef();
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(6);
    const [selectedCrime, setSelectedCrime] = useState(null)

    const points = anjos.features.map(anjo => ({
        type: "Feature",
        properties: { cluster: false,
            anjoId: anjo.properties.ANJO_ID,
            address: anjo.properties.ADDRESS,
            titulo: anjo.properties.DESCRIPTIO },
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(anjo.geometry.coordinates[0]),
                parseFloat(anjo.geometry.coordinates[1])
            ]
        }
    }));

    useEffect(() => {
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedCrime(null);
            }
        };
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, []);

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, []);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => -18.936622, lng: () => -48.273570 },
            radius: 100 * 1000, //100 metros * 1000 metros = 100km de raio
        },
    });

    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    if (loadError) return "Error";
    if (!isLoaded) return (
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>

            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
    );   
    
    return (
        <div style={{ height: "100vh", width: "100%" }}>
            
            <Locate panTo={panTo}>
                <button
                    className="locate"
                    onClick={() => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                mapRef.current.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
                                mapRef.current.setZoom(14);                               
                            },
                            () => null
                        );
                    }}
                >
                    <img src={compass} alt="compass" />
                </button>
            </Locate>

            <Search panTo={panTo}>
                <div className="search">
                    <Combobox onSelect={handleSelect}>
                        <ComboboxInput
                            value={value}
                            onChange={handleInput}
                            disabled={!ready}
                            placeholder="Digite um local"
                        />
                        <ComboboxPopover>
                            <ComboboxList>
                                {status === "OK" &&
                                    data.map(({ id, description }) => (
                                        <ComboboxOption key={id} value={description} />
                                    ))}
                            </ComboboxList>
                        </ComboboxPopover>
                    </Combobox>
                </div>
            </Search>

            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                defaultCenter={{ lat: -18.936622, lng: -48.273570 }}
                defaultZoom={6}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
            >
                                
                {clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                lat={latitude}
                                lng={longitude}
                            >
                                <div
                                    className="cluster-marker"
                                    style={{
                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                        height: `${10 + (pointCount / points.length) * 20}px`
                                    }}
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(cluster.id),
                                            20
                                        );
                                        mapRef.current.setZoom(expansionZoom);
                                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                                    }}
                                >
                                    {pointCount}
                                </div>
                            </Marker>
                        );
                    }

                    return (
                        <Marker
                            key={`crime-${cluster.properties.anjoId}`}
                            lat={latitude}
                            lng={longitude}
                        >
                            <button className="crime-marker" onMouseOver={e => {
                                e.preventDefault();
                                setSelectedCrime(cluster);
                            }}>
                                <img src={logo} alt="anjo da saude" />
                            </button>
                        </Marker>
                    );
                })}

                {selectedCrime ? (
                    <Popup
                        lat={selectedCrime.geometry.coordinates[1]}
                        lng={selectedCrime.geometry.coordinates[0]}
                    >
                        <div onMouseOut={() => {
                            setSelectedCrime(null);
                        }}>
                            <button data-balloon-pos="up-left" >
                                <h4>{selectedCrime.properties.anjoId} </h4>
                                <small> {selectedCrime.properties.address} </small>
                                <strong><p> {selectedCrime.properties.titulo} </p></strong>
                            </button>
                        </div>
                    </Popup>
                ) : null}

            </GoogleMapReact>
        </div>
    );
}


