import React from "react";
import {
    Container,
    Header,
} from "semantic-ui-react";


class Confira extends React.Component {
   
    componentDidMount() {
        
    }

    render() {
        
        return (
            <Container>
                
        {/*<MyMapComponent isMarkerShown /> */}

                <br /><br />
                <Header>Informação adicional</Header>
               
            <p style={{ fontSize: "1.13em" }}>

                
               Se você pensa em buscar um profissional para cuidar de um idoso ou ente querido de sua família, confira nossas dicas a seguir. 
               <br />
               Quem tem um familiar idoso ou ente querido que precisa de atenção e cuidados especiais diariamente sabe que a rotina não é nada fácil. 
               <br />
               Abaixo são exemplos de como podemos montar de forma rápida e eficiente um acompanhamento presencial e virtual de seu ente querido: 
                <br /><br />
                1. Melhorar a atenção ao idoso e/ou ente querido<br />
                2. Elevar a qualidade de vida da família<br />
                3. Ganhar apoio em emergências<br />
                4. Garantir o uso correto da medicação<br />
                5. Contar com um profissional qualificado<br />
                6. Oferecer o contato com outra pessoa<br />
                7. Cuidador Pessoal Virtual<br />
                8. Filtre profissional por bairro, cidade, estado, especialidade, ranking<br />
                
            </p>
            </Container>
        );
    }
}

export default Confira;
