import React, { Component } from 'react'
import { Container, Button, Header, Form, Input, TextArea,
        Message, Dimmer, Segment, Loader, Image } from 'semantic-ui-react'
import { authAxios } from "../utils";
import axios from "axios";
import { pacienteURL } from "../constants";
import { localhost } from "../constants";
//import { Link } from "react-router-dom";

class DocumentoForm extends Component {
    state = {        
        error: null,
        loading: false,
        photo: {},
        paciente: null,
        cpf: null,
        arquivo: null,
        
    };

    componentDidMount() {

    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });   
        
        let form_data = new FormData();
        form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('paciente_cpf', event.target.elements.paciente_cpf.value);
        form_data.append('tipo', event.target.elements.tipo.value);
        form_data.append('local', event.target.elements.local.value);
        form_data.append('fone1', event.target.elements.fone1.value);
        form_data.append('whatsapp', event.target.elements.whatsapp.value);
        form_data.append('username', event.target.elements.username.value);
        form_data.append('assunto', event.target.elements.assunto.value);
        form_data.append('descricao', event.target.elements.descricao.value);

        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";
        
        authAxios.post(`https://${localhost}/digitaliza/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.props.history.push(`/`);
                }
                this.setState({ loading: false });
            })
    };

    handleChange = (event) => {
        this.setState({
            loading: true,
            arquivo: event.target.files[0]
        })
        this.setState({ loading: false });
        //console.log(this.state.arquivo)
    }
       
         
    render() {

        const { error, loading } = this.state;
        return (
            <Container>
                <br /> <br />
                <Header>Digitalização de documento:</Header>

                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group widths='equal'>                        
                        <Form.Field control={Input} name="tipo" label='Tipo' placeholder='Tipo' required />
                        <Form.Field control={Input} name="assunto" label='Assunto' placeholder='Assunto' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Nome' placeholder='Paciente' required />
                        <Form.Field control={Input} name="paciente_cpf" label='CPF' placeholder='CPF apenas números' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="local" label='Endereço' placeholder='Endereço' />
                        <Form.Field control={Input} name="fone1" label='Fone' placeholder='Contato' />
                        <Form.Field control={Input} name="whatsapp" label='Whatsapp' placeholder='Celular' />
                        <Form.Field control={Input} name="username" label='Técnico' placeholder='CPF apenas números' />
                        
                    </Form.Group>


                    <Form.Field control={TextArea} name="descricao" label='Descrição' placeholder='Descrição do documento' />
                    
                    
                    <Input
                        required
                        type="file"
                        ref={(input) => { this.filesInput = input }}
                        name="file"
                        icon='file text outline'
                        iconPosition='left'
                        label='Upload FILE *'
                        labelPosition='right'
                        placeholder='UploadFILE...'
                        onChange={this.handleChange}
                    />
                    <br /> <br />
                  
                    <Form.Field control={Button} primary>Cadastrar</Form.Field>

                </Form>
            </Container>
        )
    }
}

export default DocumentoForm;
