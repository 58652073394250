import React, { Component } from 'react'
import { Container, Button, Header, Form, Input, TextArea,
            Message, Dimmer, Segment, Loader, Image } from 'semantic-ui-react'
import { authAxios } from "../utils";
import axios from "axios";
//import { Link } from "react-router-dom";
//import { pacienteURL } from "../constants";
import { localhost } from "../constants";

class SinalForm extends Component {
    state = {
        error: null,
        loading: false,
    };

    componentDidMount() {

    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true }); 
        console.log(event.target.elements)

        let form_data = new FormData();
        //form_data.append('arquivo', this.state.arquivo, this.state.arquivo.name);
        form_data.append('tipo', event.target.elements.tipo.value);
        form_data.append('assunto', event.target.elements.assunto.value);
        form_data.append('status', event.target.elements.status.value);
        form_data.append('nome', event.target.elements.nome.value);
        form_data.append('paciente_cpf', event.target.elements.paciente_cpf.value);        
        form_data.append('local', event.target.elements.local.value);
        form_data.append('fone1', event.target.elements.fone1.value);
        form_data.append('whatsapp', event.target.elements.whatsapp.value);
        form_data.append('pa', event.target.elements.pa.value);
        form_data.append('fc', event.target.elements.fc.value);
        form_data.append('temp', event.target.elements.temp.value);
        form_data.append('escala', event.target.elements.escala.value);
        form_data.append('saturacao', event.target.elements.saturacao.value);
        form_data.append('oxi', event.target.elements.oxi.value);
        form_data.append('dxt', event.target.elements.dxt.value);
        form_data.append('acessov', event.target.elements.acessov.value);
        form_data.append('svd', event.target.elements.svd.value);
        form_data.append('mews', event.target.elements.mews.value);
        form_data.append('observacao', event.target.elements.pa.value);
        form_data.append('cadastrante', event.target.elements.cadastrante.value);

        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.xsrfCookieName = "csrftoken";

        authAxios.post(`https://${localhost}/sinais/`, form_data)
            .then(res => {
                if (res.status === 201) {
                    this.props.history.push(`/`);
                }
                this.setState({ loading: false });
            })
    };

    
    render() {
        const { error, loading, } = this.state;
        return (
            <Container>
                <br /> <br />
                <Header>Cadastro de Sinais Vitais: </Header>

                {error && (
                    <Message
                        error
                        header="Ocorreu um erro"
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )} 

                <Form onSubmit={this.handleFormSubmit}>
                    <Form.Group widths='equal'>                             
                        <Form.Field control={Input} name="tipo" label='Evento' placeholder='Tipo' required />
                        <Form.Field control={Input} name="assunto" label='Assunto' placeholder='Assunto' required />
                        <Form.Field control={Input} name="status" label='Status' placeholder='Status' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="nome" label='Paciente' placeholder='Nome' required />
                        <Form.Field control={Input} name="paciente_cpf" label='CPF' placeholder='CPF apenas números' required />
                        <Form.Field control={Input} name="local" label='Endereço' placeholder='Endereço' />
                        <Form.Field control={Input} name="fone1" label='Fone' placeholder='Contato' />
                        <Form.Field control={Input} name="whatsapp" label='Whatsapp' placeholder='Celular' />
            
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="dieta" label='Aceitação Dieta' placeholder='Aceitação Dieta' />
                        <Form.Field control={Input} name="cadastrante" label='Cadastrante' placeholder='CPF apenas números' required />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="pa" label='Pressão Arterial' placeholder='Pressão Arterial' />
                        <Form.Field control={Input} name="fc" label='Frequência Card' placeholder='Frequência Card' />
                        <Form.Field control={Input} name="temp" label='Temperatura' placeholder='Temperatura' />
                        <Form.Field control={Input} name="escala" label='Escala dor' placeholder='Escala dor' />
                        <Form.Field control={Input} name="saturacao" label='Saturação' placeholder='Saturação' />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field control={Input} name="oxi" label='Oxigenoterapia L/m' placeholder='Oxigenoterapia L/m' />
                        <Form.Field control={Input} name="dxt" label='DXT' placeholder='DXT' />
                        <Form.Field control={Input} name="acessov" label='Acesso Venoso' placeholder='Acesso Venoso' />
                        <Form.Field control={Input} name="svd" label='SVD' placeholder='SVD' />
                        <Form.Field control={Input} name="mews" label='MEWS' placeholder='MEWS' />
                        
                    </Form.Group>

                    <Form.Field control={TextArea} name="observacao" label='Observação' placeholder='Observação' />
                                        
                    <Form.Field control={Button} primary>Cadastrar</Form.Field>
                        
                </Form>
            </Container>
        )
    }
}

export default SinalForm;
