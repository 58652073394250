import React from "react";
import {
    Container,
    Dimmer,
    Header,
    Image,
    Label,
    Loader,
    Table,
    Button,
    Message,
    Segment,
    Modal,
    Form,
    Input,
} from "semantic-ui-react";
import { authAxios } from "../utils";
import { Link } from "react-router-dom";
import { sinaisAdminURL, pacienteURL } from "../constants";
import Moment from 'react-moment';
import logo from './img/logo-anjos4-01.png';

class RelatorioAdmin extends React.Component {
    state = {
        data: null,
        error: null,
        loading: false,
        pacienteFiltro: null,
        formData: {
            paciente: ''
        },
        visible: true,
    };

    componentDidMount() {
        this.handleFetchSinaisAdmin();
    }

    handleFetchSinaisAdmin = () => {
        this.setState({ loading: true });
        authAxios
            .get(`https://${sinaisAdminURL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    loading: false,
                    total: res.data.length,
                });
                //console.log(res.data)
            })
            .catch(err => {
                this.setState({ error: err, loading: false, visible: true });
            });
    };

    handleFiltro = (paciente) => {
        this.setState({
            pacienteFiltro: paciente,
        });
    };

    handleChange = e => {
        const { formData } = this.state;
        const updatedFormdata = {
            ...formData,
            [e.target.name]: e.target.value
        };
        this.setState({
            formData: updatedFormdata
        });
    };

    handleDismiss = () => {
        this.setState({ visible: false, error: null })
    }
    
    render() {
        const { data, error, loading, total, pacienteFiltro, formData } = this.state;

        return (
            <Container>
                <br /> <br />
                <Header>Relatório de Sinais Vitais: <br /><br />
                    <Form onSubmit={() => this.handleFiltro(formData.paciente)}>
                        <Form.Group widths='equal'>
                            <Form.Field 
                                control={Input} 
                                name="paciente" 
                                label='Paciente' 
                                placeholder='CPF do paciente (apenas números)' 
                                onChange={this.handleChange}
                                value={formData.paciente}
                                />
                            
                        </Form.Group>
                  
                        <Form.Field control={Button} primary>Buscar</Form.Field>

                    </Form>
                </Header>
                {error && (
                    <Message
                        error
                        visible
                        onDismiss={this.handleDismiss}
                        header="Ocorreu um erro"
                        content={JSON.stringify(error.message)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>

                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                { data && (
                    (pacienteFiltro && pacienteFiltro !== '')

                    ?
                
                    <Table celled>

                        <Table.Header>
                            <Table.Row>

                                <Table.HeaderCell>Data</Table.HeaderCell>
                                <Table.HeaderCell>CPF</Table.HeaderCell>
                                <Table.HeaderCell>Paciente</Table.HeaderCell>
                                <Table.HeaderCell>Assunto</Table.HeaderCell>
                                <Table.HeaderCell>Observação</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {data.map((sinal, i) => {
                                return (
                                    (sinal.paciente_cpf.includes(pacienteFiltro))
                                        //(contato.curso == cursoFiltro) || (contato.cidade == cursoFiltro) || (contato.estado == cursoFiltro)
                                        ?
                                    <Table.Row key={i}>
                                        <Table.Cell><Moment format="DD/MM/YYYY">{sinal.created}</Moment></Table.Cell>
                                        <Table.Cell>{sinal.paciente_cpf}</Table.Cell>
                                        <Table.Cell>{sinal.nome}</Table.Cell>
                                        <Table.Cell>{sinal.assunto}</Table.Cell>

                                        <Table.Cell>


                                            <Modal trigger={
                                                <Label color="green" ribbon>
                                                    <Link>STATUS</Link>
                                                </Label>
                                            }>

                                                <Modal.Header>{sinal.nome}</Modal.Header>
                                                <Modal.Content image>

                                                    <Image wrapped size='medium' src={logo} />

                                                    <Modal.Description>
                                                        <Header><h1>Sinal aferido</h1></Header>
                                                        <p><h4>Data: <Moment format="DD/MM/YYYY">{sinal.created}</Moment></h4></p>
                                                        <p>Pressão Arterial: {sinal.pa} </p>
                                                        <p>Frequência Cardíaca: {sinal.fc} </p>
                                                        <p>Temperatura: {sinal.temp} </p>
                                                        <p>Frequência Respiratória: {sinal.fr} </p>
                                                        <p>Escala de dor: {sinal.escala} </p>
                                                        <p>Saturação: {sinal.saturacao} </p>
                                                        <p>Oxigenoterapia Litros/Min: {sinal.oxi} </p>
                                                        <p>Glicose DXT: {sinal.dxt} </p>
                                                        <p>Tipo de acesso venoso: {sinal.acessov} </p>
                                                        <p>Sonda Vesical de Demora SVD: {sinal.svd} </p>
                                                        <p>MEWS: {sinal.mews} </p>
                                                        <p>Aceitação da dieta: {sinal.dieta} </p>
                                                        <p>Observações: {sinal.observacao} </p>
                                                    </Modal.Description>
                                                </Modal.Content>

                                            </Modal>

                                            {sinal.status}

                                        </Table.Cell>
                                    </Table.Row>
                                
                                : ""
                                    );
                            })}                            

                        </Table.Body>                       

                    </Table>
                    
                            
            :
                 
                        <Table celled>

                            <Table.Header>
                                <Table.Row>

                                    <Table.HeaderCell>Data</Table.HeaderCell>
                                    <Table.HeaderCell>CPF</Table.HeaderCell>
                                    <Table.HeaderCell>Paciente</Table.HeaderCell>
                                    <Table.HeaderCell>Assunto</Table.HeaderCell>
                                    <Table.HeaderCell>Observação</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {data.map((sinal, i) => {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell><Moment format="DD/MM/YYYY">{sinal.created}</Moment></Table.Cell>
                                            <Table.Cell>{sinal.paciente_cpf}</Table.Cell>
                                            <Table.Cell>{sinal.nome}</Table.Cell>
                                            <Table.Cell>{sinal.assunto}</Table.Cell>

                                            <Table.Cell>


                                                <Modal trigger={
                                                    <Label color="green" ribbon>
                                                        <Link>STATUS</Link>
                                                    </Label>
                                                }>

                                                    <Modal.Header>{sinal.nome}</Modal.Header>
                                                    <Modal.Content image>

                                                        <Image wrapped size='medium' src={logo} />

                                                        <Modal.Description>
                                                            <Header><h1>Sinal aferido</h1></Header>
                                                            <p><h4>Data: <Moment format="DD/MM/YYYY">{sinal.created}</Moment></h4></p>
                                                            <p>Pressão Arterial: {sinal.pa} </p>
                                                            <p>Frequência Cardíaca: {sinal.fc} </p>
                                                            <p>Temperatura: {sinal.temp} </p>
                                                            <p>Frequência Respiratória: {sinal.fr} </p>
                                                            <p>Escala de dor: {sinal.escala} </p>
                                                            <p>Saturação: {sinal.saturacao} </p>
                                                            <p>Oxigenoterapia Litros/Min: {sinal.oxi} </p>
                                                            <p>Glicose DXT: {sinal.dxt} </p>
                                                            <p>Tipo de acesso venoso: {sinal.acessov} </p>
                                                            <p>Sonda Vesical de Demora SVD: {sinal.svd} </p>
                                                            <p>MEWS: {sinal.mews} </p>
                                                            <p>Aceitação da dieta: {sinal.dieta} </p>
                                                            <p>Observações: {sinal.observacao} </p>
                                                        </Modal.Description>
                                                    </Modal.Content>

                                                </Modal>

                                                {sinal.status}

                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}

                                <Table.Row>
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell textAlign="center" colSpan="2">
                                        Total de aferições: {total}
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>

                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="5">
                                        <Button floated="right" color="yellow">
                                            <Link to="relatorios">Relatórios</Link>
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>

                        </Table>                   

                )}      

            </Container>
        );
    }
}

export default RelatorioAdmin;
