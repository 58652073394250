import React, { useState } from 'react';
import {
    Container,
} from "semantic-ui-react";
import * as covidFile from './covid19-BR-1710.json';
import './Tempo.css';

const semAcentos = { "â": "a", "Â": "A", "à": "a", "À": "A", "á": "a", "Á": "A", "ã": "a", "Ã": "A", "ê": "e", "Ê": "E", "è": "e", "È": "E", "é": "e", "É": "E", "î": "i", "Î": "I", "ì": "i", "Ì": "I", "í": "i", "Í": "I", "õ": "o", "Õ": "O", "ô": "o", "Ô": "O", "ò": "o", "Ò": "O", "ó": "o", "Ó": "O", "ü": "u", "Ü": "U", "û": "u", "Û": "U", "ú": "u", "Ú": "U", "ù": "u", "Ù": "U", "ç": "c", "Ç": "C" };

function removerAcentos(s) { return s.replace(/[\W\] ]/g, function (a) { return semAcentos[a] || a }) };

const Covid = () => {
    const covid = covidFile.results.map(cidade => ({
        valores: {
            cidade: cidade.city,
            date: cidade.date,
            confirmados: cidade.last_available_confirmed,
            mortes: Math.round(parseInt(cidade.last_available_deaths) + parseInt(cidade.new_deaths)),
            populacao: cidade.estimated_population_2019,
            estado: cidade.state
        }
    }));
    const [query, setQuery] = useState('');
    const [localFiltro, setLocalFiltro] = useState('');
    const search = (e) => {
        if (e.key === 'Enter' && query !== '') {
            setLocalFiltro(query);
            setQuery('');
        }
    }

    return (

        <Container>

            <div className="container-covid">
                <input type="text" className="cidade" placeholder="Digite a cidade..." value={query} onChange={(e) => setQuery(e.target.value)} onKeyPress={search} />
                <br />
                {(localFiltro && localFiltro !== '')
                    ?
                    covid.map(c => {
                        const c1 = removerAcentos(c.valores.cidade)
                        const c2 = removerAcentos(localFiltro)
                        const areEqual = c1.toLowerCase() === c2.toLowerCase()
                        return (
                            (areEqual)
                                ?
                                <div className="city">
                                    <h2 className="city-name">
                                        <span>{c.valores.cidade}</span>
                                        <sup>{c.valores.estado}</sup>
                                    </h2>
                                    <div className="city-temp">
                                        {Math.round(c.valores.mortes)}
                                        <sup>mortes</sup>
                                    </div>
                                    <br />
                                    <div className="info">
                                        <h3><strong>{c.valores.date}</strong></h3>
                                        <h3><strong style={{ color: 'red' }}> Confirmados: {c.valores.confirmados}</strong></h3>
                                        <h3><strong style={{ color: 'green' }}> População: {c.valores.populacao}</strong></h3>
                                        <br />
                                    </div>
                                </div>
                                : ''
                        )
                    })

                    : ''
                }
            </div>
        </Container>
    );
}

export default Covid;
